
import PageNavbar from '@/components/page_navbar.vue'
import AddressPicker from '@/components/address_picker.vue'
import DeliveryAddressesEmpty from '@/components/address/delivery_addresses_empty.vue'

export default {
  components: { PageNavbar, AddressPicker, DeliveryAddressesEmpty }

  computed: {
    addresses: ->
      @$store.state.account.addresses

    loading: ->
      @$store.state.account.loading

    navigate_back_target: ->
      if @$store.getters['checkout/shipping_options'].length > 1
        '/checkout/dispatch'
      else '/basket'
  }

  data: -> { selected: null }

  methods: {
    confirm_address: ->
      return @$refs.new_address_button.$el.click() if !@selected
      @$store.commit('checkout/set_shipping_address', @selected)
      @$router.push('/checkout/confirmation')

    request_address: ->
      @$store.commit('address_picker/open')

    create_address: (new_address) ->
      @$store.dispatch('account/create_address', new_address)
  }

  created: ->
    @$store.dispatch('account/fetch_addresses')
}
